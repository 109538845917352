<template>
  <div class="flex flex-col justify-center items-center h-screen">
    <img :src="require('/assets/images/mydid-logo.png')" class="w-64 mb-10" />
    <div class="bg-white xl:w-1/3 text-center md:rounded-lg shadow-lg p-10">
      <div class="mb-10">
        <div class="flex justify-center mb-2">
          <img
            v-if="badgeTemplate"
            :src="badgeTemplate.image"
            alt=""
            class="w-64"
          />
          <div v-else class="bg-gray-100 rounded-full w-64 h-64"></div>
        </div>
        <p v-if="templateName" class="font-bold text-2xl">{{ templateName }}</p>
      </div>
      <div v-if="!expired && !waiting">
        <p class="mb-4">
          Get your custom link and retrieve your badge later by email :
        </p>
        <textarea
          type="text"
          placeholder="Enter your email address"
          rows="1"
          class="w-72 text-sm outline-none bg-gray-200 rounded-lg border-none p-3 mb-2"
          v-model="email"
        />
        <div class="w-full"></div>
        <div class="flex justify-center">
          <button
            class="w-72 text-sm bg-gray-900 text-white font-bold inline-block rounded-lg cursor-pointer py-3 px-5"
            :class="{
              'pointer-events-none opacity-50': vpRequestLoading,
            }"
            @click="createAndSendDelayedVPRequest"
          >
            <i class="fas fa-paper-plane mr-2"></i>Send custom link by email
          </button>
          <div class="relative">
            <custom-loader
              v-if="vpRequestLoading"
              class="absolute ml-2 mt-2"
              color="#111827"
              size="30px"
            >
            </custom-loader>
            <div v-else-if="emailSent" class="absolute ml-2 mt-2">
              <i class="fas fa-check-circle text-3xl text-green-700"></i>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="expired">
        <p class="text-red-500 text-lg font-bold">
          Unfortunately you're too late !
        </p>
        <p>Badge is not available anymore</p>
      </div>
      <div v-else-if="waiting">
        <p class="text-red-500 text-lg font-bold">You're too soon !</p>
        <p>Badge is not available yet</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, watch, computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const api = inject('api');
    const utils = inject('utils');

    const id = route.params.id;

    const badgeTemplate = ref(null);
    const templateName = ref(null);
    const vpRequest = ref(null);
    const vpRequestLoading = ref(null);
    const buttonDisable = ref(false);
    const email = ref('maxime.dutres@mydid.com');
    const startDate = ref(null);
    const expirationDate = ref(null);
    const expired = computed(() => {
      return vpRequest.value && expirationDate.value
        ? new Date() > new Date(expirationDate.value)
        : null;
    });
    const waiting = computed(() => {
      return vpRequest.value && startDate.value
        ? new Date() < new Date(startDate.value)
        : null;
    });
    const emailSent = ref(false);

    if (id) {
      api
        .getVPRequest(id)
        .then((res) => {
          vpRequest.value = res.data;
          const templateHash = vpRequest.value.challenge.split('::')[1];
          startDate.value = vpRequest.value.delegationBadge.startDate;
          expirationDate.value = vpRequest.value.delegationBadge.expirationDate;
          templateName.value =
            vpRequest.value.delegationBadge.credentialSubject.endorsementComment.split(
              '::'
            )[1];

          utils.ipfs
            .getJsonDataFromCID(utils.ipfs.hashToCID(templateHash))
            .then((data) => {
              badgeTemplate.value = data;
            });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // loading.value = false;
    }

    async function createAndSendDelayedVPRequest() {
      vpRequestLoading.value = true;

      api
        .createAndSendDelayedVPRequest({
          email: email.value,
          challenge: id,
        })
        .then((res) => {
          vpRequestLoading.value = false;
          emailSent.value = true;
        });
    }

    function isMobile() {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    }

    return {
      isMobile,
      createAndSendDelayedVPRequest,
      badgeTemplate,
      templateName,
      vpRequest,
      vpRequestLoading,
      buttonDisable,
      email,
      expired,
      waiting,
      emailSent,
    };
  },
};
</script>
